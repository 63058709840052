/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/fullcalendar-scheduler@5.3.2/main.min.js
 * - /npm/fullcalendar-scheduler@5.3.2/locales-all.min.js
 * - /npm/fullcalendar-scheduler@5.3.2/locales-all.min.js
 * - /npm/fullcalendar-scheduler@5.3.2/main.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
